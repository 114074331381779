// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  protocol: 'https',
  environment: 'dev',
  host: 'dev.eu1.goci.io',
  dashboardUrl: 'https://dev.eu1.goci.io/dashboard',
  telemetry: {
    dataset: 'development',
  },

  backend: {
    scheme: 'https',
    host: 'api.dev.goci.io',
  },

  features: {
    deleteConnections: true,
  },

  auth: {
    scope: 'openid email profile',
    clientId: 'Un7MEFLGq4wutM66a2T5YbGsVpQKC26k',
    rootConnection: 'goci-tenants-dev',
    domain: 'goci-prod-europe.eu.auth0.com',
    audience: 'https://dev.goci.io/',
    redirectUri: 'https://dev.eu1.goci.io/callback/dashboard',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
