import { NgModule, ModuleWithProviders, InjectionToken } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService, AuthServiceConfig } from './auth.service';
import { Router, RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule.forChild([]),
  ],
})
export class AuthModule {
  static forRoot(configs: AuthModuleConfig[]): ModuleWithProviders<AuthModule> {
    const providers = [];
    configs.forEach(c => {
      const configToken = new InjectionToken<AuthServiceConfig>(c.serviceToken.toString() + 'Config');

      providers.push(
        { provide: configToken, useValue: c.config },
        { provide: c.serviceToken, useClass: AuthService, deps: [Router, configToken]}
      );
    });

    return {
      ngModule: AuthModule,
      providers,
    };
  }
}

export interface AuthModuleConfig {
  serviceToken: InjectionToken<AuthService>;
  config: AuthServiceConfig;
}
