// Modified Version of https://auth0.com/docs/quickstart/spa/angular2/01-login
// Modified to support multi Client use on a single Page and Authorize Flow for external Clients

import createAuth0Client, { RedirectLoginResult, Auth0Client } from '@auth0/auth0-spa-js';
import { from, of, Observable, BehaviorSubject, combineLatest, throwError } from 'rxjs';
import { tap, catchError, concatMap, shareReplay, take, filter, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, PLATFORM_ID } from '@angular/core';

export class AuthService {
  private setupError: any;

  // Create an observable of Auth0 instance of client
  auth0Client$: Observable<Auth0Client>;

  // Define observables for SDK methods that return promises by default
  // For each Auth0 SDK method, first ensure the client instance is ready
  // concatMap: Using the client instance, call SDK method; SDK returns a promise
  // from: Convert that resulting promise into an observable
  isAuthenticated$: Observable<boolean>;
  handleRedirectCallback$: Observable<RedirectLoginResult>;

  // Create subject and public observable of user profile data
  private userProfileSubject$ = new BehaviorSubject<any>(null);
  userProfile$ = this.userProfileSubject$.asObservable();
  private userClaimsSubject$ = new BehaviorSubject<any>(null);
  userClaims$ = this.userClaimsSubject$.asObservable();
  // Create a local property for login status
  loggedIn: boolean = null;

  private callbackCompleted = new BehaviorSubject<boolean>(null);
  callbackCompleted$ = this.callbackCompleted.asObservable();

  constructor(private router: Router,
              private config: AuthServiceConfig,
              @Inject(PLATFORM_ID) private platformId) {
    if (!this.config.skipLocalSetup) {
      this.setupClient(false);
    }
  }

  login(redirectPath: string, connection: string) {
    // A desired redirect path can be passed to login method
    // (e.g., from a route guard)
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      const urlNdParams = (redirectPath || '').split('?');
      const params = urlNdParams.length > 1 ? urlNdParams.pop().split('&') : [];

      // Call method to log in
      client.loginWithRedirect({
        redirect_uri: this.config.redirectUri,
        // remove query params, especially code and state params
        appState: { target: urlNdParams.shift(), queryParams: params },
        prompt: this.config.prompt,
        scope: this.config.scope,
        connection,
      });
    });
  }

  logout() {
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log out
      client.logout({
        client_id: this.config.clientId,
        returnTo: this.config.returnTo || 'https://goci.io',
      });
    });
  }

  signup(redirectPath: string, connection: string) {
    this.auth0Client$.subscribe((client) => {
      client.loginWithRedirect({
        redirect_uri: this.config.redirectUri,
        appState: { target: (redirectPath || '/').split('?').shift() },
        prompt: this.config.prompt,
        login_hint: 
        connection,
      })
    })
  }

  getAccessToken(audience?: string): Observable<string> {
    return this.auth0Client$.pipe(concatMap(client => client.getTokenSilently({
      audience: audience || this.config.audience,
    })));
  }

  askForAuthorization(): Observable<void> {
    if (!this.isSetup()) {
      this.setupClient(true);
    }
    
    return this.auth0Client$.pipe(
      concatMap(client => client.loginWithPopup({
        prompt: this.config.prompt,
        display: 'popup',
      })),
      take(1),
      tap(() => this.setupClient(false))
    );
  }

  setupClient(silent?: boolean) {
    this.ensureClient(this.config.rootConnection, silent);
    this.handleRedirectCallback$ = this.auth0Client$.pipe(
      filter(Boolean),
      concatMap((client: Auth0Client) => from(client.handleRedirectCallback()))
    );

    this.isAuthenticated$ = this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getUser()).pipe(map(Boolean))),
      tap(res => this.loggedIn = res)
    );

    // Handle redirect from Auth0 login
    this.handleAuthCallback();

    // On initial load, check authentication state with authorization server
    // Set up local auth streams if user is already authenticated
    this.localAuthSetup();
  }

  handleAuthCallback() {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    // Call when app reloads after user logs in with Auth0
    const params = window && window.location.search;
    if (params.includes('code=') && params.includes('state=')) {
      let targetRoute: string, queryParams = {}; // Path to redirect to after login processsed
      const authComplete$ = this.handleRedirectCallback$.pipe(
        // Have client, now call method to handle auth callback redirect
        tap(cbRes => {
          // Get and set target redirect route from callback results
          if (cbRes.appState) {
            targetRoute = cbRes.appState.target || '/dashboard';
            (cbRes.appState.queryParams || []).forEach(param => {
              const keyVal = param.split('=');
              queryParams[keyVal[0]] = keyVal[1];
            });
          }
        }),
        concatMap(() => {
          // Redirect callback complete; get user and login status
          return combineLatest([
            this.getUser$(),
            this.getTokenClaims$(),
          ]);
        })
      );
      // Subscribe to authentication completion observable
      // Response will be an array of user and login status
      authComplete$.subscribe(
        ([user, claims]) => {
          // Redirect to target route after callback processing
          this.callbackCompleted.next(true);
          this.router.navigate([targetRoute], {
            queryParams,
          });
        },
        err => {
          this.log('error', err);
          this.callbackCompleted.next(false);
        }
      );
    }
  }

  isSetup(): boolean {
    return Boolean(this.auth0Client$ && !this.setupError);
  }

  isSetupWithError(): boolean {
    return Boolean(this.auth0Client$ && this.setupError);
  }

  ensureClient(connection?: string, silent?: boolean): void {
    if (this.auth0Client$ && !this.setupError || isPlatformServer(this.platformId)) {
      return;
    }

    this.setupError = null;
    this.auth0Client$ = from(createAuth0Client({
      domain: this.config.domain,
      client_id: this.config.clientId,
      audience: this.config.audience,
      scope: this.config.scope || 'openid profile email',
      redirect_uri: this.config.redirectUri || (window && window.location.origin),
      connection,
    })).pipe(
      shareReplay(1),
      catchError(err => {
        this.setupError = err;
        this.log('error', 'Error while creating Auth Client', err);
        if (silent) {
          return of(new Auth0Client({
            domain: this.config.domain,
            client_id: this.config.clientId,
            audience: this.config.audience,
            redirect_uri: this.config.redirectUri || (window && window.location.origin),
            connection,
          }));
        } else {
          return throwError(err);
        }
      })
    );
  }

  // When calling, options can be passed if desired
  // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
  private getUser$(options?): Observable<any> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getUser(options))),
      tap(user => this.userProfileSubject$.next(user))
    );
  }

  private getTokenClaims$(options?): Observable<any> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getIdTokenClaims(options))),
      tap(claims => this.userClaimsSubject$.next(claims))
    );
  }

  private localAuthSetup() {
    // This should only be called on app initialization
    // Set up local authentication streams
    const checkAuth$ = this.isAuthenticated$.pipe(
      concatMap((loggedIn: boolean) => {
        if (loggedIn) {
          // If authenticated, get user and set in app
          // NOTE: you could pass options here if needed
          return combineLatest([this.getUser$(), this.getTokenClaims$()]);
        }
        // If not authenticated, return stream that emits 'false'
        return of(loggedIn);
      })
    );
    checkAuth$.subscribe();
  }

  private log(type, msg, data?) {
    if (isPlatformBrowser(this.platformId)) {
      window && window.console[type](msg, data);
    } else {
      console[type](msg, data);
    }
  }
}

export interface AuthServiceConfig {
  domain: string;
  scope?: string;
  clientId: string;
  returnTo?: string;
  audience?: string;
  redirectUri?: string;
  silentSetup?: boolean;
  rootConnection?: string;
  skipLocalSetup?: boolean;
  prompt?: 'none' | 'login' | 'consent' | 'select_account' | any;
}
