import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RequiresAuthGuard } from './requires-auth.guard';
import { CallbackComponent } from './callback/callback.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./homepage/homepage.module').then(m => m.HomepageModule),
    //redirectTo: '/dashboard',
  },
  {
    path: 'callback/:client',
    component: CallbackComponent,
  },
  {
    path: 'dashboard',
    canActivate: [RequiresAuthGuard],
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    relativeLinkResolution: 'legacy',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
