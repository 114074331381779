import { Injectable, Inject, InjectionToken } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth/auth.service';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export const DashboardAuthService = new InjectionToken<AuthService>('dashboardAuth');

@Injectable({
  providedIn: 'root'
})
export class RequiresAuthGuard implements CanActivate {
  constructor(@Inject(DashboardAuthService) private auth: AuthService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
      return this.auth.isAuthenticated$.pipe(
        tap(loggedIn => {
          if (!loggedIn) {
            this.auth.login(state.url, environment.auth.rootConnection);
          }
        })
      );
  }
}
