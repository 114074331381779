import { Injectable, Inject } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { AuthService } from './auth/auth.service';
import { DashboardAuthService } from './requires-auth.guard';
import { environment } from 'src/environments/environment';

@Injectable()
export class BackendTokenInterceptor implements HttpInterceptor {
  constructor(@Inject(DashboardAuthService) private auth: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.indexOf('/contact?') > -1) {
      return next.handle(request);
    }
    if (request.url.indexOf(environment.backend.host) < 0) {
      return next.handle(request);
    }

    return this.auth.userClaims$.pipe(
      concatMap(claims => {
        const token = claims['__raw'];
        if (!token) {
          return next.handle(request);
        }

        const clone = request.clone({
          headers: new HttpHeaders({
            Authorization: 'Bearer ' + token,
          })
        });

        return next.handle(clone);
      })
    );
  }
}
