<div class="callback">
    <p *ngIf="!failed; else authFailed">Authorizing...</p>
</div>

<ng-template #authFailed>
    <p>
        <strong>Auth Failed</strong><br />
        <br />
        <a href="/dashboard" title="Back to goci.io Dashboard">goci.io Dashboard</a><br />
        <a href="https://goci.io" title="goci.io Website">goci.io Website</a>
    </p>
</ng-template>
