import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { DashboardAuthService } from '../requires-auth.guard';
import { environment } from 'src/environments/environment';
import { Subscription, throwError } from 'rxjs';
import { catchError, take, debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit, OnDestroy {
  private awaitCallback: Subscription;
  failed = false;

  constructor(@Inject(DashboardAuthService) private auth: AuthService) { }

  ngOnInit(): void {
    this.auth.ensureClient(environment.auth.rootConnection, false);
    this.awaitCallback = this.auth.callbackCompleted$
      .pipe(debounceTime(150), take(1), catchError(err => {
        this.failed = true;
        return throwError(err);
      }))
      .subscribe(callback => {});
  }

  ngOnDestroy() {
    this.awaitCallback.unsubscribe();
  }
}
