import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthModule } from './auth/auth.module';
import { environment } from 'src/environments/environment';
import { DashboardAuthService } from './requires-auth.guard';
import { CallbackComponent } from './callback/callback.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BackendTokenInterceptor } from './backend-token.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    CallbackComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({
      appId: 'goci-dashboard',
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    AuthModule.forRoot([{
      config: environment.auth,
      serviceToken: DashboardAuthService,
    }])
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BackendTokenInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
